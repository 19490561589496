import * as React from "react"
import {Link} from "gatsby"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const PreEventPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=pre-event_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu changeLang={changeLang} lang={lang}/>

            <div id="duogram"></div>

            {lang != "en"&&

                <>
                    <h1>Sosialt arrangement</h1>
                    <p>
                        Kvelden før bryllupet, altså søndag den 10. juli, vil det bli en sosial samling med
                        enkel bevertning og drikke i familien til Knut sitt sommerhus f.o.m. kl 19:00.
                    </p>

                    <p>
                        Adressen er <a target={"_blank"} href={"https://goo.gl/maps/nw6D4AJCHAr8L6DN9"}>Aristotelous 1, Ag. Panteleimon 190 07</a>.
                        Pilen på kartet i Google Maps viser korrekt hus (men ikke bildet i Google Street View). Dette er 5-10 minutter unna Golden Coast med bil,
                        eventuelt ca 30 minutter å gå (langs sjøen).
                    </p>

                    <p>
                        Det hele blir veldig uformelt og tilbakelent. Antrekk er valgfritt.
                    </p>

                </>

            }
            {lang == "en" &&
                <>
                    <h1>Pre-wedding event</h1>
                    <p>
                        The evening before the wedding, on July 10th, there will be a social pre-wedding event
                        with some snacks and drinks in the summer house of Knut's family from 7PM.
                    </p>

                    <p>
                        The address is <a target={"_blank"} href={"https://goo.gl/maps/nw6D4AJCHAr8L6DN9"}>Aristotelous 1, Ag. Panteleimon 190 07</a>.
                        The arrow in Google maps shows the correct house (not the image in Google Street View). It is about 5-10 minutes away from Golden Coast by car,
                        or about 30 minutes to walk (seaside).
                    </p>

                    <p>
                        This event will be very relaxed and informal. No dress code - you are free to wear whatever you like.
                    </p>

                </>
            }

        </main>
    )
}

export default PreEventPage
